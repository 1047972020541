













































































import Vue from 'vue';
import { AuthService } from '@/lib/services';
import { Component } from 'vue-property-decorator';
import { unwrapError } from '@/lib/helpers';
import Logo from '@/components/Logo.vue';

@Component({
  components: { Logo },
})
export default class Recovery extends Vue {
  email: string = '';
  loading: boolean = false;
  requested: boolean = false;

  async requestPassword(): Promise<void> {
    try {
      this.loading = true;
      await AuthService.resetPasswordRequest(this.email);
      this.requested = true;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.recover.request'),
        unwrapError(e),
      );
    } finally {
      this.loading = false;
    }
  }
}
